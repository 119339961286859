import { splitProps } from "solid-js";
import { createRegionInfoInputs } from "../form";
import { FormPageProps } from "../model";
import { createStore } from "solid-js/store";
import { Cmp, Mod } from ":mods";
import { updateShowExhibition } from "../apis/show-exhibition";

export function RegionInfo(props: FormPageProps) {
  const FormInputs = createRegionInfoInputs();
  const [local, others] = splitProps(props, ["stage_id", "data"]);

  const [regionData, setRegionData] = createStore({
    key: "box-03",
    regional_courses_and_dates: 1,
  });
  const onSubmit = async (e: Event) => {
    e.preventDefault();
    const values = FormInputs.Actions.getValuesSnakecase();

    console.log("values", values);
    setRegionData("regional_courses_and_dates", parseInt(values.region));
    const formData = new FormData();
    console.log("JSON.stringify(regionData)", JSON.stringify(regionData));
    formData.append("data", JSON.stringify(regionData));
    await updateShowExhibition(local.stage_id, formData).then((message) => {
      console.log(message, " :message");
      Cmp.Alert.actions.pushToast({
        type: "success",
        message: "Region Info Updated successfully",
      });
    });
  };

  return (
    <main class="flex flex-col items-center w-full justify-between">
      <section class="flex flex-col gap-40px w-full pb-80px pt-40px">
        <form class="flex flex-col gap-20px">
          <p class="pt-2  w-521px">
            If you were accepted onto the programme, which regions would you be able to participate on the in-person
            training in? You will be responsible for your own travel and accommodation expenses
          </p>

          <div class="flex flex-col gap-10px">
            <FormInputs.region.Label title="What is your highest academic qualification">
              <FormInputs.region.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.region.Label>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.region.Input type="radio" class="w-23px h-23px" placeholder="" name="academicfield" id="1" />
              <p class="text-16px">Central - September 2023</p>
            </div>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.region.Input type="radio" class="w-23px h-23px" placeholder="" name="academicfield" id="2" />
              <p class="text-16px">Northern - October 2023</p>
            </div>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.region.Input type="radio" class="w-23px h-23px" placeholder="" name="academicfield" id="3" />
              <p class="text-16px">Southern - November 2023</p>
            </div>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.region.Input type="radio" class="w-23px h-23px" placeholder="" name="academicfield" id="3" />
              <p class="text-16px">Eastern - December 2023</p>
            </div>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.region.Input type="radio" class="w-23px h-23px" placeholder="" name="academicfield" id="4" />
              <p class="text-16px">Western - January 2023</p>
            </div>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.region.Input type="radio" class="w-23px h-23px" placeholder="" name="academicfield" id="5" />
              <p class="text-16px">None - I wont be able to attend in person</p>
            </div>
          </div>

          <div class="flex justify-end">
            <FormInputs.Actions.Button
              class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-175px h-44px text#n-50  h-23px mt-20px mb-80px px-30px py-20px rounded-sm"
              onclick={onSubmit}
              statusValid={"Save and Close"}
              statusInvalid={"Save and Close"}
            />
          </div>
        </form>
      </section>
    </main>
  );
}
